import { post } from "../../../../static/assets/utilities/ajax.js";
import './jquery-counter.js';
import bootstrapcomponent from './../../../../static/assets/js/bootstrap-component.js';
class Slick_General {

    constructor() {
        this.init();
        this.bootstrap = new bootstrapcomponent();
    }

    init() {
        this.sliderElement();
        this.sliderDetailsPage();
        this.hoverEffect();
        this.setInitialDirection();
        this.eventhandler();
    }

    eventhandler() {
        // Call hoverEffect on window resize
        jQuery(window).resize(() => {
            this.hoverEffect(); // Call the hoverEffect method on window resize
        });

        jQuery(document.body).on("change", 'input[name="theme_scheme_direction"]', this.changeLanguageDirection.bind(this));

        //Episode slider
        jQuery(document.body).on("click", '.init-episode-slider', this.InitEpisodeSlider.bind(this));

        // Change season
        jQuery(document.body).on("click", ".css_prefix-change-season", this.GetSeasonEpisodes.bind(this));

        //Remove Continue Watching
        jQuery(document.body).on("click", ".continue_watch_empty", this.RemoveContinueWatching.bind(this));
    }

    setInitialDirection() {
        const htmlTag = jQuery("html");
        const initialDir = htmlTag.attr("dir") || "ltr";
        const savedDir = this.getCookie("theme_scheme_direction") || initialDir;

        jQuery(`#theme-scheme-direction-${savedDir}`).addClass("active");
        jQuery(`#theme-scheme-direction-${savedDir === "ltr" ? "rtl" : "ltr"}`).removeClass("active");
    }

    changeLanguageDirection(event) {
        this.bootstrap.closeVisibleOffcanvas('#rtl-switcher-options');
        const selectedDir = jQuery(event.target).val();
        jQuery("html").attr("dir", selectedDir);
        this.setCookie("theme_scheme_direction", selectedDir, 7);

        jQuery(`#theme-scheme-direction-${selectedDir}`).addClass("active");
        jQuery(`#theme-scheme-direction-${selectedDir === "ltr" ? "rtl" : "ltr"}`).removeClass("active");


        this.reinitializeAllSliders();
        this.reinitsliderDetailsPage();
    }

    setCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 864e5)); // Days to milliseconds
        document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
    }

    getCookie(name) {
        return document.cookie.split('; ').reduce((acc, cookie) => {
            const [key, val] = cookie.split('=');
            return key === name ? val : acc;
        }, null);
    }

    sliderDetailsPage() {
        const sliders = jQuery('.single_page_slick');
        if (sliders.length > 0) {
            sliders.each((index, element) => {
                const sliderElement = jQuery(element).find('.css_prefix-slick-general');
                if (sliderElement.length > 0 && !sliderElement.hasClass('slick-initialized')) {
                    this.initSlickSlider(sliderElement, this);
                }
            });
        }
    }

    reinitsliderDetailsPage() {
        const sliders = jQuery('.single_page_slick');

        if (sliders.length > 0) {
            sliders.each((index, element) => {
                const sliderElement = jQuery(element).find('.css_prefix-slick-general');

                if (sliderElement.length > 0) {
                    // Unslick if already initialized
                    if (sliderElement.hasClass('slick-initialized')) {
                        sliderElement.slick('unslick'); // Unslick the slider
                    }

                    // Re-initialize the Slick slider
                    this.initSlickSlider(sliderElement, this);
                }
            });
        }
    }


    sliderElement() {

        if (typeof elementor == 'undefined') {
            jQuery(() => {
                this.addElementorHook('frontend/element_ready/widget');
            });
        } else {
            elementor.on('preview:loaded', () => {
                this.addElementorHook('frontend/element_ready/widget');
            });
        }
    }

    reinitializeAllSliders() {
        const sliderTypes = [
            { selector: '.css_prefix-slick-general', initMethod: this.initSlickSlider },
            { selector: '.css_prefix-vertical-thumbnail-banner', initMethod: this.initVTBannerSlider },
            { selector: '.css_prefix-main-banner', initMethod: this.initBannerSlider },
            { selector: '.css_prefix-simple-banner', initMethod: this.initSlickSlider },
            { selector: '.css_prefix-tvshow-season', initMethod: this.initTvShowSeason, extraMethods: [this.ajaxChangeSeason] },
            { selector: '.css_prefix-tvshow-tab', initMethod: this.tvShowTabSlider, extraMethods: [this.tvShowSeasonChange] },
            { selector: '.css_prefix-product-banner', initMethod: this.initSlickSlider },
            { selector: '.st-count-down', initMethod: this.callTimer }
        ];

        sliderTypes.forEach(({ selector, initMethod, extraMethods = [] }) => {
            const elements = jQuery(selector);

            // Ensure we're handling each element separately
            elements.each((index, element) => {
                const $element = jQuery(element);


                // Unslick if initialized
                if ($element.hasClass('slick-initialized')) {
                    $element.slick('unslick');
                }

                // Reinitialize the slider
                this.initializeSlider($element, initMethod, extraMethods);
            });
        });
    }




    addElementorHook(hook) {

        const sliderTypes = [
            { selector: '.css_prefix-slick-general', initMethod: this.initSlickSlider },
            { selector: '.css_prefix-vertical-thumbnail-banner', initMethod: this.initVTBannerSlider },
            { selector: '.css_prefix-main-banner', initMethod: this.initBannerSlider },
            { selector: '.css_prefix-simple-banner', initMethod: this.initSlickSlider },
            { selector: '.css_prefix-tvshow-season', initMethod: this.initTvShowSeason, extraMethods: [this.ajaxChangeSeason] },
            { selector: '.css_prefix-tvshow-tab', initMethod: this.tvShowTabSlider, extraMethods: [this.tvShowSeasonChange] },
            { selector: '.css_prefix-product-banner', initMethod: this.initSlickSlider },
            { selector: '.st-count-down', initMethod: this.callTimer }
        ];
        if (typeof elementorFrontend !== 'undefined') {
            elementorFrontend.hooks.addAction(hook, ($scope) => {

                sliderTypes.forEach(({ selector, initMethod, extraMethods = [] }) => {
                    const elements = $scope.find(selector);
                    this.initializeSlider(elements, initMethod, extraMethods);
                });
            });
        }
    }


    initializeSlider(elements, initMethod, extraMethods = []) {
        if (elements.length > 0 && !elements.hasClass('slick-initialized')) {
            // Initialize the slider with the initMethod

            initMethod.call(this, elements, this);

            // Call extra methods after slider initialization
            extraMethods.forEach(method => {
                // Ensure each extraMethod gets the correct context and necessary arguments
                method.call(this, elements, this); // Pass elements and `this` context if needed
            });
        }
    }

    /**
     * Handles the logic for changing TV show seasons, including initializing sliders,
     * populating episode data, and updating content based on season selection.
     */
    tvShowSeasonChange(args = {}) {
        const is_rtl = document.documentElement.getAttribute('dir') === 'rtl';

        /**
         * Initializes the Slick Slider for the episodes carousel.
         * @param {jQuery} sliderTarget - The jQuery object of the slider element.
         */
        const initializeSlider = (sliderTarget) => {
            // Initialize the slider only if it hasn't been initialized already
            if (!sliderTarget.hasClass('slick-initialized')) {

                sliderTarget.slick({
                    rtl: is_rtl,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: false,
                    dots: true,
                    draggable: false,
                    nextArrow: '<button class="NextArrow-two"><i class="icon-arrow-right"></i></button>',
                    prevArrow: '<button class="PrevArrow-two"><i class="icon-arrow-left"></i></button>',
                    responsive: [
                        { breakpoint: 1368, settings: { slidesToShow: 3 } },
                        { breakpoint: 1025, settings: { slidesToShow: 3 } },
                        { breakpoint: 993, settings: { slidesToShow: 2 } },
                        { breakpoint: 577, settings: { slidesToShow: 1 } }
                    ],
                });
            }
        };

        /**
         * Populates the slider with episode content retrieved from the response.
         * @param {jQuery} sliderSelector - The jQuery selector for the slider.
         * @param {Object} json_res - The JSON response containing episode data.
         * @returns {number} - The number of updated items in the slider.
         */
        const populateSliderContent = (sliderSelector, json_res) => {
            let length = 0;

            // Iterate through the existing slides and populate content
            sliderSelector.find('.slick-slide').each((key, value) => {

                const $slide = jQuery(value);
                if (typeof json_res['result'][key] === 'undefined') {
                    $slide.remove(); // Remove any extra items
                    return;
                }
                // Set the block content for each episode
                $slide.find('.episode-card').html(json_res['result'][key]['block_main_content'])
                    .removeClass('skeleton').addClass('animate');
                length = key + 1; // Track how many items were updated
            });

            return length; // Return the count of updated items
        };

        /**
         * Adds any remaining episodes to the slider.
         * @param {jQuery} sliderSelector - The jQuery selector for the slider.
         * @param {Object} json_res - The JSON response containing episode data.
         * @param {number} startIndex - The index at which to start adding episodes.
         */
        const addRemainingEpisodes = (sliderSelector, json_res, startIndex) => {
            // Loop through remaining episodes and add them to the slider

            for (let index = startIndex; index < json_res['result'].length; index++) {

                const element = json_res['result'][index];
                const cloned_item = sliderSelector.find('.episode-item').last().clone();

                // Update the content of the cloned item
                cloned_item.find('.block-image').empty().append(element['block_img']);
                cloned_item.find('.episodes-description').empty().append(element['block_contnt']);

                // Add the cloned item to the slider
                sliderSelector.slick('slickAdd', cloned_item);
            }
        };

        // Event delegation for the season select change event
        jQuery(document.body).on('change', '.season-select', function () {
            const btn = jQuery(this);
            const episode = btn.parents('.trending-custom-tab').find('.css_prefix-tv_show-episodes').data('episodes');
            const season_no = btn.val(); // Get the selected season number
            const target = btn.parents('.trending-custom-tab').find('.episodes-contens');

            // Check if the content for the selected season is already loaded
            if (target.children(`[data-display="${season_no}"]`).length) {
                jQuery('.episodes-slider').hide();
                jQuery(`.episodes-slider[data-display="${season_no}"]`).show();
                return;
            }

            const episode_count = episode[season_no].episodes.length;

            // Generate the HTML for the new slider based on episode count
            let sliderHTML = `<div class="episodes-slider slick-slider-tvshow-tab ajax-slick-load" data-display="${season_no}">`;

            for (let i = 0; i < episode_count; i++) {
                sliderHTML += '<div class="episode-item animated fadeInUp ajax	"><div class="episode-card"></div></div>';
            }
            sliderHTML += '</div>';
            target.append(sliderHTML);

            // Hide all sliders initially, then show the one for the selected season
            jQuery('.episodes-slider').hide();
            jQuery(`.episodes-slider[data-display="${season_no}"]`).show();

            // Make the API call to get the season data

            post('tvshow_tab_seasons_data', { data: episode, season: season_no })
                .then(res => {
                    let json_res = JSON.parse(res);
                    if (json_res['success']) {
                        const sliderSelector = target.find(`.episodes-slider[data-display="${season_no}"]`);
                        const carousel = initializeSlider(sliderSelector); // Initialize the slider
                        const length = populateSliderContent(sliderSelector, json_res);
                        addRemainingEpisodes(sliderSelector, json_res, length); // Add remaining episodes
                    }
                });
        });

        // Event delegation for the episode button click (one-time click)
        jQuery(document.body).on('click', '.css_prefix-tv_show-episodes', function () {
            const btn = jQuery(this);
            const season_no = 0; // Default season number
            const episode = btn.data('episodes');
            const target = btn.closest('.trending-custom-tab').find('.episodes-contens');

            const sliderTarget = target.find('.episodes-slider');

            // Make the API call to get season data
            post('tvshow_tab_seasons_data', { data: episode, season: season_no })
                .then(res => {
                    let json_res = JSON.parse(res); // Parse the response
                    if (json_res['success']) {
                        // Initialize the slider and populate content
                        initializeSlider(sliderTarget);
                        const length = populateSliderContent(sliderTarget, json_res);
                        addRemainingEpisodes(sliderTarget, json_res, length);
                    }
                });
        });
    }


    tvShowTabSlider(args = {}) {
        // Select all the elements that represent TV show tab sliders
        var tabSlider = jQuery('.css_prefix-tvshow-tab');
        const is_rtl = document.documentElement.getAttribute('dir') === 'rtl';

        // Iterate through each tabSlider element
        tabSlider.each(function () {
            var $this = jQuery(this);  // Wrap the current element in a jQuery object for easier manipulation

            // Generate unique selectors for the main slider and the navigation slider based on data attributes
            var trendingSlider = '#' + $this.find('.trending-slider-tab').attr('data-rand');
            var trendingSliderNav = '#' + $this.find('.trending-slider-tab-nav').attr('data-rand');

            const totalSlides = jQuery(trendingSlider).children().length;
            if (totalSlides === 1) {
                jQuery(trendingSlider).addClass('single-slide');
            }

            // Initialize the main trending slider only if it hasn't been initialized yet
            if (!jQuery(trendingSlider).hasClass('slick-initialized')) {
                jQuery(trendingSlider)
                    // Use 'afterChange' event to update the active tabs whenever the slide changes
                    .on('afterChange', function (_, slick, currentSlide) {
                        // Get the unique random ID associated with the slider instance
                        var randID = slick.$slider.data('rand_id');

                        // Find the tab contents for the current slide (overview and episodes)
                        var overviewTab = jQuery('#overview_' + (currentSlide + 1) + randID);
                        var episodesTab = jQuery('#episodes_' + (currentSlide + 1) + randID);

                        // Check if the episodes tab is not active, and if not, set the overview tab as active
                        if (!episodesTab.hasClass('active show')) {
                            // Update the navigation link for the overview tab to reflect the active state
                            jQuery('.streamit-trending-pills .nav-link[href="#overview_' + (currentSlide + 1) + randID + '"]').addClass('active');
                            overviewTab.addClass('active show');
                        }
                    })
                    // Initialize the slick slider with the desired settings
                    .slick({
                        lazyLoad: 'ondemand',
                        rtl: is_rtl,
                        slidesToShow: 1,  // Show one slide at a time in the main slider
                        slidesToScroll: 1,  // Scroll one slide at a time
                        arrows: true,  // Show navigation arrows
                        fade: true,  // Enable fade effect for the slides
                        draggable: false,  // Disable drag to scroll
                        infinite: true,
                        swipe: false,  // Disable swipe for mobile devices
                        touchMove: false,  // Disable touch move for better control
                        asNavFor: trendingSliderNav,  // Link this main slider to the navigation slider
                        nextArrow: '<button class="NextArrow-two"><i class="icon-arrow-right"></i></button>',  // Next arrow HTML
                        prevArrow: '<button class="PrevArrow-two"><i class="icon-arrow-left"></i></button>',  // Previous arrow HTML
                    });


            }

            // Initialize the navigation slider only if it hasn't been initialized yet
            if (!jQuery(trendingSliderNav).hasClass('slick-initialized')) {
                jQuery(trendingSliderNav)
                    // Initialize the slick slider with settings for the navigation
                    .slick({
                        rtl: is_rtl,
                        lazyLoad: 'ondemand',
                        slidesToShow: 5,  // Show 5 slides in the navigation slider
                        slidesToScroll: 1,  // Scroll one slide at a time
                        asNavFor: trendingSlider,  // Link this navigation slider to the main slider
                        dots: false,  // Disable dots for navigation
                        arrows: false,  // Disable arrows for navigation
                        infinite: true,  // Allow infinite looping of navigation slides
                        centerMode: true,  // Enable center mode for better visibility of the active slide
                        centerPadding: 0,  // No extra padding around the center slide
                        focusOnSelect: true,  // Focus the selected slide in the navigation
                        responsive: [
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 3,  // Show 3 slides for smaller screens
                                    slidesToScroll: 1,  // Scroll one slide at a time
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 3,  // Show 3 slides for mobile screens
                                    slidesToScroll: 1,  // Scroll one slide at a time
                                }
                            },
                            {
                                breakpoint: 401,
                                settings: {
                                    slidesToShow: 3,  // Show 3 slides for very small mobile screens
                                    slidesToScroll: 1,  // Scroll one slide at a time
                                }
                            }
                        ]
                    });

            }
        });
    }


    /**
     * Fetches TV show seasons data and dynamically updates the season and episode content.
     * @param {Object} data - The data to be sent with the AJAX request.
     * @param {jQuery} $this - The jQuery object of the element triggering the AJAX call.
     * @param {number} first_tvshow_id - The ID of the first TV show for comparison.
     */
    ajaxCall(data, $this, first_tvshow_id) {
        // Get the closest parent element that contains the TV show season data
        const mainParent = $this.closest(".css_prefix-tvshow-season-parent");

        // Make an AJAX post request to fetch the TV show seasons data
        post('tvshow_seasons_data', data)
            .then(res => {
                // Parse the response data
                const json_res = res.data;

                // Check if the response is a valid array and has data
                if (Array.isArray(json_res) && json_res.length > 0) {
                    // Check if new content is available before clearing existing data
                    const hasSeasonContent = json_res.some(value => value['season_content']);
                    const hasBlockContent = json_res.some(value => value['block_content']);
                    const hasButtonContent = json_res.some(value => value['button_content']);

                    // Clear existing data only if new content is available

                    if (hasSeasonContent) {
                        mainParent.find('.nav-tabs-inner').empty(); // Clear seasons
                    }
                    if (hasBlockContent) {
                        mainParent.find('.season-episodes-details').empty(); // Clear episodes
                    }
                    if (hasButtonContent) {
                        mainParent.find('.view-all-btn-parent').empty(); // Clear "view all" button
                    }

                    // Hide existing episodes meta if tvshow_id in the response does not match the first TV show ID
                    if (json_res[0]['tvshow_id'] !== undefined && json_res[0]['tvshow_id'] !== first_tvshow_id) {
                        mainParent.find('.nav-tabs-inner .css_prefix-episodes-meta').hide();
                    }

                    // Loop through the response and append season and episode content
                    json_res.forEach(value => {
                        // Check and append season content if exists
                        if (value['season_content']) {
                            mainParent.find('.nav-tabs-inner').append(value['season_content']);
                            // Set the first season as active and mark it as loaded
                            mainParent.find('.tvshow-' + json_res[0]['tvshow_id']).first()
                                .addClass('active')
                                .data('ajax_loaded', true);
                        }

                        // Append block content for episodes if available
                        if (value['block_content']) {
                            mainParent.find('.season-episodes-details').append(value['block_content']);
                        }

                        // Append the "view all" button if it exists; otherwise, hide the button container
                        if (value['button_content']) {
                            mainParent.find('.view-all-btn-parent').append(value['button_content']);
                        } else {
                            mainParent.find('.view-all-btn-parent').hide();
                        }
                    });

                    // Mark the button as loaded and set the active class for the first TV show tab
                    $this.data('ajax_loaded', true);
                    mainParent.find('.tvshow-' + json_res[0]['tvshow_id']).first()
                        .addClass("active")
                        .data('ajax_loaded', true);

                    // Remove the skeleton loader once content is loaded
                    mainParent.find('.skeleton-box').remove();
                } else {
                    // Handle case when no episodes are found in the response
                    mainParent.find('.season-episodes-details').append("<div class='episodes-info'>No Episodes Found</div>");
                }
            })
            .catch(err => {
                // Log error if the AJAX request fails
                console.error("Error fetching TV show seasons data:", err);
            });
    }


    /**
     * Initializes the TV Show Season Slick Slider with advanced configurations.
     * @param {jQuery} sliderElement - The jQuery element representing the slider.
     */
    initTvShowSeason(sliderElement, args = {}) {

        // Get slider settings and extra settings from data attributes
        const slickSettings = sliderElement.data('slider_settings');
        const extraSettings = sliderElement.data('extra_settings');
        const is_rtl = document.documentElement.getAttribute('dir') === 'rtl';

        // Add extra settings if necessary
        if (extraSettings === true) {
            slickSettings.nextArrow = '<button class="NextArrow-two"><i class="icon-arrow-right"></i></button>';
            slickSettings.prevArrow = '<button class="PreArrow-two"><i class="icon-arrow-left"></i></button>';
        }

        // If slickSettings is not defined, exit early
        if (!slickSettings) {
            return;
        }
        slickSettings.rtl = is_rtl;
        slickSettings.lazyLoad = 'ondemand';


        // Cache repeated selectors to avoid querying DOM multiple times
        const detailWrap = sliderElement.closest('.css_prefix-wrap-details');
        const episodeCardText = detailWrap.find('.p-btns').data('episode_card_text');
        const navTabsInner = detailWrap.find('.nav-tabs-inner');
        const firstTvShowId = navTabsInner.data('first_tvshow_id');

        // Initialize the slider with the defined settings
        jQuery(sliderElement)
            .on('afterChange', (event, slick, currentSlide) => {
                // Get the current slide element and its associated data
                const element = jQuery(sliderElement).find(`[data-slick-index=${currentSlide}]`);
                const tvShowId = element.data("post");

                // Remove the active class from all nav-tabs-inner links
                navTabsInner.find("a").removeClass('active');

                // Check if the element's data has already been loaded
                const data = {
                    'tvshow_id': tvShowId,
                    'season_change': true,
                    'season_number': 0, // Update this dynamically if needed
                    'episode_card_text': episodeCardText,
                    'first_tvshow_id': firstTvShowId
                };

                // Call the AJAX function for loading data, use arrow function to maintain `this`
                this.ajaxCall(data, element, firstTvShowId, currentSlide);

                // Hide all details initially
                detailWrap.find('.season-episodes-details .episodes-info').hide();
                detailWrap.find('.css_prefix-episodes-meta').hide();

                // Select and show the active seasons and episodes based on TV show ID
                const showSeasonsSelector = `.tvshow-${tvShowId}`;
                const showEpisodesSelector = `.tvshow-${tvShowId}-season-1`; // You can update the season number dynamically

                detailWrap.find(showSeasonsSelector).first().addClass('active').show();
                detailWrap.find(showEpisodesSelector).show();
            })
            .slick(slickSettings);
    }



    /**
     * Handles season change via AJAX when an episode meta button is clicked.
     */
    ajaxChangeSeason() {
        // Use event delegation for dynamically loaded elements
        jQuery(document).on("click", '.css_prefix-episodes-meta', (event) => {
            const $this = jQuery(event.currentTarget);
            const parent = $this.closest('.css_prefix-wrap-details');

            // Cache commonly accessed elements
            const episodeCardText = parent.find('.p-btns').data('episode_card_text');
            const tvShowId = $this.data('tvshow-id');
            const seasonNumber = parseInt($this.data('season'), 10);
            const ajaxLoaded = $this.data('ajax_loaded');

            // Determine the next season
            const nextSeasonNumber = seasonNumber + 1;
            const $showSeason = `.tvshow-${tvShowId}-season-${nextSeasonNumber}`;

            // Clear active class and hide current season details
            parent.find('.css_prefix-episodes-meta').removeClass('active');
            parent.find('.season-episodes-details .episodes-info').hide();

            // Mark the clicked season as active
            $this.addClass('active');

            // Show the new season's details and apply fade-in animation
            jQuery($showSeason).show().addClass("fade-in-anim");

            // If the episodes info has the fade-in-anim class, remove it
            parent.find('.episodes-info').removeClass("fade-in-anim");

            // Prepare the AJAX data to load the new season's content
            const data = {
                'action': 'tvshow_seasons_data',
                'tvshow_id': tvShowId,
                'season_change': false,
                'season_number': seasonNumber,
                'episode_card_text': episodeCardText,
            };

            // Only trigger AJAX call if the data isn't already loaded
            this.ajaxCall(data, $this, seasonNumber);

        });
    }


    /**
      * Initializes the vertical banner slider with synchronized main and thumbnail sliders.
      * @param {jQuery} vtbanner - The vertical banner container element(s) to initialize the slider for.
      */
    initVTBannerSlider(vtbanner, args = {}) {

        const is_rtl = document.documentElement.getAttribute('dir') === 'rtl';
        const $this = jQuery(vtbanner);
        const $verticalBannerContent = $this.find('.vertical-banner-content');
        const $verticalBannerThumb = $this.find('.vertical-banner-thumb');

        // Check if the slick slider is already initialized
        if ($verticalBannerContent.hasClass('slick-initialized')) {
            $verticalBannerContent.slick('unslick');
        }
        if ($verticalBannerThumb.hasClass('slick-initialized')) {
            $verticalBannerThumb.slick('unslick');
        }

        // Get unique random IDs for each slider
        const verticalBannerContentId = '#' + $verticalBannerContent.attr('data-rand');
        const verticalBannerThumbId = '#' + $verticalBannerThumb.attr('data-rand');

        // Get parent wrappers for arrows
        const $arrowForVertical = $this.find('.vertical-banner-thumb-wrapper');
        const $arrowParent = $this.find('.vertical-banner-content');

        // Arrow buttons for both sliders
        const nextArrow = '<button class="NextArrow-two"><i class="icon-arrow-right"></i></button>';
        const prevArrow = '<button class="PreArrow-two"><i class="icon-arrow-left"></i></button>';

        // Initialize the main content slider
        jQuery(verticalBannerContentId).slick({
            rtl: is_rtl,
            slidesToShow: 1,
            arrows: true,
            fade: true,
            lazyLoad: 'ondemand',
            asNavFor: verticalBannerThumbId,
            appendArrows: $arrowForVertical,
            nextArrow: nextArrow,
            prevArrow: prevArrow,
            responsive: [{
                breakpoint: 992,
                settings: {
                    asNavFor: false,
                    nextArrow: nextArrow,
                    prevArrow: prevArrow,
                }
            }]
        });

        // Initialize the thumbnail slider
        jQuery(verticalBannerThumbId).slick({
            slidesToShow: 3,
            asNavFor: verticalBannerContentId,
            dots: false,
            arrows: true,
            infinite: true,
            vertical: true,
            verticalSwiping: true,
            centerMode: true,
            lazyLoad: 'ondemand',
            appendArrows: $arrowParent,
            nextArrow: nextArrow,
            prevArrow: prevArrow,
            focusOnSelect: true,
        });

        // Force Slick to update after initialization
        setTimeout(() => {
            jQuery(verticalBannerContentId).slick('setPosition');
            jQuery(verticalBannerThumbId).slick('setPosition');
        }, 300);

    }


    /**
     * Initializes the banner slider with slick carousel, including thumbnail navigation.
     * @param {jQuery} bannerSlider - The banner slider container element to initialize.
     * @param {Object} args - Optional arguments (e.g., RTL setting).
     */
    initBannerSlider(bannerSlider, args = {}) {
        // Ensure the bannerSlider has not already been initialized
        if (!bannerSlider.hasClass('slick-initialized')) {
            const is_rtl = document.documentElement.getAttribute('dir') === 'rtl';
            const $this = bannerSlider; // Current slider element

            // Get the ID of the current slider and create the corresponding selectors
            const iqBannerThumbSliderId = $this.attr('id');
            const bannerThumbSlider = '#' + iqBannerThumbSliderId;

            // Get the thumbnail navigation slider ID from the closest parent
            const bannerThumbSliderNavId = $this.closest(".st-main-slider").find('.banner-thumb-slider-nav').data('rand');
            const bannerThumbSliderNav = "#" + bannerThumbSliderNavId;

            // Get Slick settings from data attributes
            const slickSettings = $this.data('slider_settings') || {};
            const slickChildSettings = $this.data('slick_child_settings') || {};

            // Define arrow buttons for both sliders
            const nextArrow = '<button class="NextArrow-two"><i class="icon-arrow-right"></i></button>';
            const prevArrow = '<button class="PreArrow-two"><i class="icon-arrow-left"></i></button>';

            // Set the Slick slider options for both the main and thumbnail sliders 
            slickSettings.asNavFor = bannerThumbSliderNav;
            slickSettings.rtl = is_rtl;
            slickSettings.lazyLoad = 'ondemand',


                slickChildSettings.asNavFor = bannerThumbSlider;
            slickChildSettings.nextArrow = nextArrow;
            slickChildSettings.prevArrow = prevArrow;
            slickChildSettings.rtl = is_rtl;
            slickChildSettings.lazyLoad = 'ondemand';

            // Initialize the main banner slider if it's defined
            if (jQuery(bannerThumbSlider).length > 0) {
                jQuery(bannerThumbSlider).slick(slickSettings);
            }

            // Initialize the thumbnail navigation slider if it's defined using the simplified approach
            if (jQuery(bannerThumbSliderNav).length > 0) {
                // Directly use slick initialization here as you requested
                jQuery(bannerThumbSliderNav).slick(slickChildSettings);
            }
        }
    }

    /**
     * Initializes a Slick slider with custom settings and applies 'first' and 'last' classes to active slides.
     * @param {jQuery} sliderElement - The slider element to initialize.
     */
    initSlickSlider(sliderElement, args = {}) {

        // Check if the slider has already been initialized
        if (!sliderElement.hasClass('slick-initialized')) {
            const is_rtl = document.documentElement.getAttribute('dir') === 'rtl';


            let slickSettings = sliderElement.data('slider_settings');
            slickSettings.lazyLoad = 'ondemand';
            // Use slingle_slider_settings if defined and non-empty
            const singleSliderSettings = sliderElement.data('slingle_slider_settings');
            if (singleSliderSettings) {
                slickSettings = singleSliderSettings;
            }

            // Apply extra settings for navigation arrows
            const extraSettings = sliderElement.data('extra_settings');
            if (extraSettings === true) {
                slickSettings.nextArrow = '<button class="NextArrow-two"><i class="icon-arrow-right"></i></button>';
                slickSettings.prevArrow = '<button class="PreArrow-two"><i class="icon-arrow-left"></i></button>';
            }

            slickSettings.rtl = is_rtl;

            // Initialize the slider if settings are available
            if (slickSettings) {
                // Initialize the Slick slider with the provided settings
                sliderElement.slick(slickSettings);


                // Cache slide items and active slide elements
                const slideItems = sliderElement.find('.slick-slide');
                const activeSlides = sliderElement.find('.slick-active');

                // Add "first" and "last" classes to the initially active slides
                activeSlides.first().addClass('first');
                activeSlides.last().addClass('last');

                // After the slide changes, update the "first" and "last" classes
                sliderElement.on('afterChange', function () {
                    // Remove "first" and "last" from all slides
                    slideItems.removeClass('first last');

                    // Get the new active slide
                    const newActiveSlides = sliderElement.find('.slick-active');

                    // Add "first" and "last" classes to the new active slide
                    newActiveSlides.first().addClass('first');
                    newActiveSlides.last().addClass('last');
                });
            }
        }
    }


    /**
     * Updates 'first' and 'last' classes for the active slides after a change in the slider.
     * @param {jQuery} sliderElement - The slider element to update.
     */
    updateFirstLastClassesAfterChange(sliderElement) {
        const slideItems = sliderElement.find(".slick-track .slick-slide");
        const activeSlides = sliderElement.find(".slick-active");

        // Remove 'first' and 'last' classes from all slides
        slideItems.removeClass("first last");

        // Add 'first' and 'last' classes to the active slides
        activeSlides.first().addClass("first");
        activeSlides.last().addClass("last");
    }

    /**
     * Initializes the 'first' and 'last' classes for the active slides when the slider is initialized.
     * @param {jQuery} sliderElement - The slider element to initialize.
     */
    updateFirstLastClassesInit(sliderElement) {
        const activeSlides = sliderElement.find('.slick-active');

        // Add 'first' and 'last' classes to the initially active slides
        activeSlides.first().addClass("first");
        activeSlides.last().addClass("last");
    }

    /**
     * Initializes a countdown timer for a given element.
     * @param {HTMLElement} element - The element containing the countdown data attributes.
     */
    callTimer(element, args = {}) {
        const $element = jQuery(element);
        const futureDate = $element.attr('data-date');
        const label = $element.attr('data-labels') === "true";
        const displayFormat = $element.attr('data-format');

        // Initialize the countdown timer
        $element.countdowntimer({
            dateAndTime: futureDate,
            labelsFormat: label,
            displayFormat: displayFormat,
        });
    }

    /**
     * Applies hover effects and attaches watchlist update listeners.
     */
    hoverEffect() {
        if (jQuery(window).width() <= 767) return; // Skip hover effects on small screens

        let hoverTimeout; // Store timeout for removing the cloned item
        let hoverDelayTimeout; // Timeout for adding delay before showing hover effect

        jQuery('.display-hover-effect').hover(
            function (e) {
                clearTimeout(hoverTimeout); // Clear any removal timeout
                clearTimeout(hoverDelayTimeout); // Clear previous hover delay

                const _this = jQuery(e.currentTarget);

                // Add a delay before triggering the hover effect
                hoverDelayTimeout = setTimeout(() => {
                    const isRtl = document.documentElement.getAttribute('dir') === 'rtl';
                    const $hiddenData = _this.find('.hidden-hover-data');

                    // Clone the hidden hover data and create the hover effect
                    const $clone = $hiddenData.clone().removeAttr('style').addClass('cloned-item');
                    const offset = _this.offset();
                    const width = _this.outerWidth();
                    const height = _this.outerHeight();
                    const overlapPerc = 15;
                    const overlap = (overlapPerc / 100) * width;
                    const newWidth = width + overlap * 2;
                    const newHeight = height + overlap * 2;

                    let left = offset.left - (newWidth - width) / 2;
                    let top = offset.top - (newHeight - height) / 2;

                    // Remove existing clones before creating a new one
                    jQuery('.cloned-item').remove();
                    $clone.css({
                        position: 'absolute',
                        left: `${left}px`,
                        top: `${top}px`,
                        width: `${newWidth}px`,
                        height: `${newHeight}px`,
                        'z-index': 990,
                        'border-radius': '12px',
                        background: 'rgba(0, 0, 0, 0.1)',
                        opacity: 0,
                        transition: 'opacity 0.3s ease, transform 0.3s ease',
                        transform: 'scale(0.7)',
                        'pointer-events': 'auto',
                    });

                    jQuery('body').append($clone);

                    $clone.find('.block-images').on('click', function (event) {
                        event.preventDefault(); // Prevent default click behavior

                        const $anchor = jQuery(this).find('a'); // Find the anchor tag inside .block-images
                        if ($anchor.length > 0) {
                            const href = $anchor.attr('href'); // Get the href attribute
                            if (href) {
                                window.location.href = href; // Redirect to the URL
                            }
                        }
                    });

                    // Delegate the 'watchlistUpdated' event to 'body'
                    jQuery('.cloned-item').on('watchlistUpdated', (event, data) => {
                        const button = $hiddenData.find('.watch-list-btn');
                        const action = data.action;

                        // Update the button based on the action
                        if (action === 'add') {
                            button.addClass('in-watchlist')
                                .find('i').removeClass('icon-plus').addClass('icon-check-2');
                            button.attr('data-bs-title', 'Remove from watchlist');
                            button.attr('data-action', 'remove');
                        } else {
                            button.removeClass('in-watchlist')
                                .find('i').removeClass('icon-check-2').addClass('icon-plus');
                            button.attr('data-bs-title', 'Add to watchlist');
                            button.attr('data-action', 'add');
                        }
                    });

                    // Apply the hover effect transition
                    setTimeout(() => {
                        const transformStyles = { opacity: 1, transform: 'scale(0.9)' };
                        if (_this.hasClass('first')) {
                            transformStyles.transform += isRtl ? ' translateX(-13.5%)' : ' translateX(13.5%)';
                        } else if (_this.hasClass('last')) {
                            transformStyles.transform += isRtl ? ' translateX(13.5%)' : ' translateX(-13.5%)';
                        }
                        $clone.css(transformStyles);
                    }, 500);

                    // Handle hover on the cloned card
                    $clone.hover(
                        function () {
                            clearTimeout(hoverTimeout); // Prevent removal while hovering over the cloned item
                        },
                        function () {
                            // Remove the cloned card when the mouse leaves it
                            hoverTimeout = setTimeout(() => $clone.remove(), 100);
                        }
                    );
                }, 500); // Add a delay of 200ms before triggering the hover effect
            },
            function () {
                clearTimeout(hoverDelayTimeout); // Cancel hover effect if mouse leaves before delay
                const $clone = jQuery('.cloned-item');
                // Set a timeout to remove the cloned card after hover out
                hoverTimeout = setTimeout(() => {
                    if (!$clone.is(':hover')) {
                        $clone.remove();
                    }
                }, 100);
            }
        );
    }



    /**
    * Handles the event for fetching and displaying episodes for a selected season.
    * 
    * @param {Object} event - The event triggered by the user interaction.
    */
    GetSeasonEpisodes(event) {
        event.preventDefault(); // Prevent the default behavior of the event (such as following a link).

        // Helper function to populate the slider content with episode data
        const populateSliderContent = (sliderSelector, json_res) => {
            let length = 0;

            // Iterate through each slide in the slider and update the content
            sliderSelector.find('.slick-slide').each((key, value) => {
                const $slide = jQuery(value);
                // If there's no episode data for this slide, remove it
                if (typeof json_res['result'][key] === 'undefined') {
                    $slide.remove();
                    return;
                }
                // Update the content inside each episode card
                $slide.find('.episode-card').html(json_res['result'][key]['block_main_content']);
                length = key + 1; // Track the number of slides processed
            });

            return length; // Return the number of episodes processed
        };

        // Helper function to initialize the slick slider if it's not already initialized
        const initializeSlider = (sliderTarget, slick_settings, extra_settings) => {

            if (!sliderTarget.hasClass('slick-initialized')) {
                // If additional settings are provided, customize the navigation arrows
                if (extra_settings) {
                    slick_settings.nextArrow = '<button class="NextArrow-two"><i class="icon-arrow-right"></i></button>';
                    slick_settings.prevArrow = '<button class="PrevArrow-two"><i class="icon-arrow-left"></i></button>';
                }
                // Initialize the slick slider with the settings
                sliderTarget.slick(slick_settings);
            }
        };

        const btn = event.currentTarget; // The button that triggered the event

        // Accessing data attributes from the clicked button
        const season_no = jQuery(btn).data('season_no'); // The season number
        const episode = jQuery(btn).data('episodes'); // The episode data
        const is_slider = jQuery(btn).data('is-slider'); // Whether the episodes should be displayed in a slider
        const result = is_slider === undefined ? true : (is_slider === false ? false : true); // Set slider display flag

        const targetId = jQuery(btn).attr("href"); // Get the href of the clicked button (the target tab)
        const target = jQuery(targetId); // Select the corresponding .tab-pane element

        const slick_settings = jQuery('.css_prefix-slick-general').data('slider_settings'); // Get slider settings
        const extra_settings = jQuery('.css_prefix-slick-general').data('extra_settings'); // Get extra slider settings

        const sliderTarget = target.find('.css_prefix-slick-general'); // The slider container for episodes

        // Check if content for the selected season is already loaded
        if (jQuery(btn).attr('data-display') == season_no) {
            // If content is already loaded, just display it
            return;
        }

        // Update the display data attribute for the button and slider
        jQuery(btn).attr('data-display', season_no);
        jQuery(sliderTarget).attr('data-display', season_no);

        let episode_count = episode[season_no].episodes.length; // Get the number of episodes for this season
        let sliderHTML = '';
        // Create HTML for each episode item in the slider
        for (let i = 0; i < episode_count; i++) {
            if (i === 0) {
                // Add 'first' class to the first element
                sliderHTML += '<div class="slick-slide slick-item first"><div class="episode-card"></div></div>';
            } else if (i === episode_count - 1) {
                // Add 'last' class to the last element
                sliderHTML += '<div class="slick-slide slick-item last"><div class="episode-card"></div></div>';
            } else {
                // Regular element
                sliderHTML += '<div class="slick-slide slick-item"><div class="episode-card"></div></div>';
            }
        }
        // sliderHTML += '</div>'; // Close the slider container
        sliderTarget.append(sliderHTML); // Append the slider HTML to the episode content

        // Send an AJAX request to fetch the episode data for the selected season
        post('tvshow_tab_seasons_data', { data: episode, season: season_no, is_slider: result })
            .then(res => {
                try {
                    let json_res = JSON.parse(res); // Parse the response into a JSON object

                    // Check if the response indicates success
                    if (json_res['success']) {
                        const sliderSelector = target.find(`.css_prefix-slick-general[data-season_no="${season_no}"]`);
                        if (result) {
                            jQuery(sliderSelector).slick('destroy')
                            // If the result is a slider, initialize the slick slider and populate the content
                            const carousel = initializeSlider(sliderSelector, slick_settings, extra_settings);
                            const length = populateSliderContent(sliderSelector, json_res); // Populate the slider content with episodes
                        } else {

                            // If it's not a slider, append the episode content directly to the page
                            json_res['result'].forEach((item, key) => {
                                target.find('.css_prefix-episodes-content').append(item['block_main_content']);
                            });
                        }

                    } else {
                        console.error("Error: Response success is false", json_res); // Handle error in response
                    }
                } catch (e) {
                    console.error("Error parsing JSON response", e); // Handle error parsing the JSON response
                }
            })
            .catch(err => {
                console.error("Error during AJAX request", err); // Handle error during the AJAX request
            });
    }




    InitEpisodeSlider(e) {
        e.preventDefault();
        const current_target = jQuery(e.currentTarget);
        const targetId = jQuery('.init-episode-slider').attr("href");
        const season_no = current_target.data('season_no');
        const sliderSelector = jQuery(`.css_prefix-slick-general[data-display="${season_no}"]`);
        jQuery(sliderSelector).slick('destroy')
        jQuery(sliderSelector).slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: false,
            arrows: false,
            dots: false,
            draggable: true,
            responsive: [
                { breakpoint: 1368, settings: { slidesToShow: 3 } },
                { breakpoint: 1025, settings: { slidesToShow: 3 } },
                { breakpoint: 993, settings: { slidesToShow: 2 } },
                { breakpoint: 577, settings: { slidesToShow: 2 } }
            ],
        });
    }

    /**
     * Handles the "Remove Continue Watching" button functionality.
     * Sends an AJAX request to remove a post from the continue watching list.
     * 
     * @param {Event} event - The click event on the "Remove Continue Watching" button.
     */
    RemoveContinueWatching(event) {
        // Get the clicked button element
        var button = event.currentTarget;
        // Retrieve the closest slick item (container) for the button
        const slickItem = button.closest('.slick-slide'); // Adjust the selector as per your slick item structure

        jQuery(button).tooltip('dispose');
        // Retrieve data attributes from the button (post type and post ID)
        var postType = button.dataset.postType || '';  // The type of the post being removed from the continue watching list
        var id = button.dataset.id || '';  // The ID of the post being removed

        // Prepare the data to be sent in the AJAX request
        var data = { post_type: postType, post_id: id };

        // Send an AJAX request to remove the post from the continue watching list
        post('remove_continue_watch', data)
            .then(res => {
                // Log the response from the server (for debugging or confirmation)
                slickItem.remove();
            })
            .catch(err => {
                // Log any errors that occur during the AJAX request
                console.log(err);
            });
    }


}


new Slick_General();
